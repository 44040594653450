<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 17:03:53
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 12:20:28
 * @FilePath: \crm-education\src\layouts\c\HeaderBar.vue
-->
<template>
  <div class="header-bar">
    <div class="main-width">
      <a-row>
        <a-col :xs="{ span: 12 }">
          <div
            class="header-left d-flex align-center"
            @click="userRole === 'STUDENT' ? $router.push({ path: '/student' }) : $router.push({ path: '/teacher' })"
          >
            <div class="header-log"></div>
            <span class="pl-5 fs-18">{{ userRole === 'STUDENT' ? '学习空间' : '教师空间' }}</span>
          </div>
        </a-col>
        <a-col :xs="{ span: 12 }">
          <div class="header-right d-flex align-center justify-end">
            <div
              @click="$router.push({ name: 'student_my_notice' })"
              class="header-message mr-20 link"
              :class="noReadMsg ? 'unread' : ''"
            ></div>
            <a-dropdown>
              <div class="header-user d-flex align-center link" style="width: auto">
                <a-avatar :size="24" icon="user" :src="user.studentImg" ></a-avatar>
                <span class="pl-5">{{ user.studentName }}</span>
              </div>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="$router.push({ name: 'student_user_center' })">个人信息</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="switchAccount()">账号切换</a>
                </a-menu-item>
                <a-menu-item v-if="isShowWxLogin === 'Y'">
                  <a href="javascript:;" @click="relationAccount()">关联账号</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="logout">退出</a>
                </a-menu-item>
                <!-- <a-menu-item>
                <a href="javascript:;">选项一</a>
              </a-menu-item> -->
              </a-menu>
            </a-dropdown>
          </div>
        </a-col>
      </a-row>
      <a-modal :visible="relationAccountVisible" @cancel="relationAccountVisible = false" :footer="false" width="40%">
        <div class="section-header dec">
          <h2 class="base-title fs-18">关联第三方账号</h2>
          <span style="color: red;position: relative;top: 10px;">*关联后可使用第三方账号直接登陆学习空间</span>
          <div style="border-bottom: 1px solid rgb(215, 220, 220);margin-top: 30px;"></div>
        </div>
          <a-list :content="accountData">
            <a-list-item >
              <p class="py-20 fs-16"><img src="@/assets/img/login/wx.png" alt="" style="width: 30px"/>微信</p>
              <p class="py-20 fs-16">{{ accountData.isRelation === 'Y' ? '已关联'+ '('+ accountData.nickname + '）' : '未关联' }}</p>
              <div class="py-20">
                <a-button class="cus-button" type="primary" v-show="accountData.isRelation === 'Y'" @click.native= "unbound">解除关联</a-button>
                <a-button class="cus-button" type="primary" v-show="accountData.isRelation === 'N'"  @click.native= "binding">关联</a-button>
              </div>
            </a-list-item>
          </a-list>
      </a-modal>
      <a-modal  :visible="wxLoginVisible"
                :footer="false"
                width="350px"
                :maskClosable="false"
                @cancel="wxLoginVisible = false">
        <!-- 放置二维码的div -->
        <div id="login_container">
          <iframe ></iframe>
        </div>
      </a-modal>
      <wx-relation-dialog ref="wxRelationDialog"></wx-relation-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import helper from '@/utils/helper'
import api from '@/api'
import wxlogin from '@/utils/wxLogin'
import WxRelationDialog from "../../components/business/wx/RelationAccount";

export default {
  name: 'header_bar',
  components: {
      WxRelationDialog,
  },
  data() {
    return {
      // hasUnread: true,
      // userInfo: helper.getUserInfo()
        relationAccountVisible: false,
        accountData: {},
        wxLoginVisible: false,
        isShowWxLogin: localStorage.getItem("isShowWxLogin")
    }
  },
  computed: {
    ...mapState(['userRole', 'noReadMsg', 'user'])
  },
  mounted:function(){
      /*监听路由是否有code值*/
      if(this.$route.query.code != undefined) {
          //请求后端微信绑定接口
          if (this.$route.query.type === 'null') {
              this.bindingAccount(this.$route.query.code,this.$route.query.state);
          } else if (this.$route.query.type === 'downloadData') {
              this.$refs.wxRelationDialog.bindingAccount(this.$route.query.code,this.$route.query.state)
          }
      }
  },
  methods: {
    logout() {
      helper.clearUserInfo()
      this.$router.replace({ name: 'login_student' })
    },
    async switchAccount(){
      let  res =  await api.student.getOStudId({organizationId:localStorage.getItem("organizationId")});
      if(res.data=='N'){
        this.$message.warning('没有可切换的其他账号！');
        return;
      }
      localStorage.setItem('userId', res.data);
      this.$message.loading('切换中...');
      setTimeout(function(){},500);
      this.$router.go('0');
    },
      async relationAccount() {
        this.relationAccountVisible = true
        this.getRelationAccountData()
      },
      async getRelationAccountData() {
          let res = await api.student.getRelationAccountData()
          this.accountData = res.data
      },
       unbound() {
        let self = this
        let data = this.accountData
        this.$confirm({
            title: '是否解除与该微信账号的关联关系？',
            content: '',
            okText: '是',
            cancelText: '否',
            onOk() {
                  api.student.unboundData(data).then(res => {
                    if (res.code === 200) {
                        self.$message.success("解除成功! ")
                        self.relationAccount()
                    } else {
                        self.$message.error("解除失败：" + res.data)
                    }
                })
            },
            onCancel() {
            },
        });

      },
      async unboundData() {
          let data = this.accountData
          let res = api.student.unboundData(data)
          if (res.code === 200){
              this.relationAccount();
          }
      },
      async binding() {
          //生成微信登录二维码
          this.wechatCode()
          this.wxLoginVisible = true
      },
      async wechatCode () {
          let organizationId = localStorage.getItem('organizationId');
          let url = process.env.VUE_APP_WX_API_BASE_URL + "/student/redirect?organizationId=" + organizationId + "&env=" + process.env.NODE_ENV;
          let wxAppId = process.env.VUE_APP_WX_APP_ID;
          //记住一定要注明文件类型是css
          var blob = new Blob([""],{type: "text/css;charset=utf-8"});
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function(e) {
              var obj = new WxLogin({
                  self_redirect: false,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
                  id:"login_container", //div的id
                  appid: wxAppId,
                  scope: "snsapi_login", //固定内容
                  redirect_uri:encodeURIComponent(url) //回调地址
              })
          }
      },
      async bindingAccount(code,state) {
          this.accountData.code = code
          let res = await api.student.bindingAccount(this.accountData)
          if (res.code === 200) {
              this.$router.push({ query: {} });
              this.wxLoginVisible = false;
              this.relationAccount();
          }
      }
  }
}
</script>

<style lang="scss">
.header-bar {
  height: 64px;
  .header-left {
    cursor: pointer;
  }
  .header-log {
    display: inline-block;
    width: 40px;
    height: 28px;
    background: url('../../assets/img/logo.png') no-repeat center center;
  }
  .header-message {
    width: 24px;
    height: 18px;
    background: url('../../assets/img/header-msg.png') no-repeat center center;
    &.unread {
      position: relative;
      &::after {
        position: absolute;
        right: -4px;
        top: -4px;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #fc3f3f;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .header-user {
    width: 100px;
  }
}
</style>
