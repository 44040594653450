/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 21:31:15
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-06 21:52:05
 * @FilePath: \crm-education\src\mixins\custom-icon.js
 */

import { HomeSvg } from '@/utils/custom-icon'
const CusHomeIcon = {
  template: '<a-icon :component="HomeSvg" />',
  data () {
    return {
      HomeSvg: HomeSvg
    }
  }
}
export default {
  components: {
    CusHomeIcon: CusHomeIcon
  }
}