/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 21:28:32
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-06 21:41:42
 * @FilePath: \crm-education\src\utils\custom-icon.js
 */
export const HomeSvg = {
  template: `<svg 
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18px" height="19px">
   <defs>
   <filter id="Filter_0">
       <feFlood flood-color="rgb(255, 255, 255)" flood-opacity="1" result="floodOut" />
       <feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
       <feBlend mode="normal" in="compOut" in2="SourceGraphic" />
   </filter>
   
   </defs>
   <g filter="url(#Filter_0)">
   <path fill-rule="evenodd"  fill="rgb(100, 104, 103)"
    d="M10.019,1.351 C9.468,0.851 8.629,0.851 8.079,1.351 L0.234,8.808 C-0.058,9.077 -0.080,9.532 0.186,9.827 C0.452,10.122 0.906,10.148 1.203,9.884 L1.328,9.766 L1.328,17.549 C1.327,17.934 1.480,18.303 1.751,18.575 C2.023,18.847 2.391,19.000 2.775,19.000 L6.987,19.000 L6.987,14.638 C6.987,14.367 7.094,14.107 7.285,13.915 C7.477,13.723 7.736,13.616 8.007,13.616 L9.989,13.616 C10.259,13.616 10.519,13.723 10.710,13.915 C10.901,14.107 11.009,14.367 11.008,14.638 L11.008,19.000 L15.320,19.000 C15.705,19.000 16.073,18.847 16.345,18.575 C16.616,18.303 16.769,17.934 16.768,17.549 L16.768,9.861 C17.090,10.152 17.546,10.130 17.813,9.832 C17.942,9.689 18.009,9.501 17.999,9.309 C17.989,9.117 17.904,8.937 17.761,8.808 L10.019,1.351 L10.019,1.351 Z"/>
   </g>
   </svg>`
}
