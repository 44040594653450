<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:31:20
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-08 09:15:45
 * @FilePath: \crm-education\src\layouts\Base.vue
-->
<template>
  <div class="main" v-wechat-title="protalTitle">
    <a-layout theme="light">
      <a-layout-header>
        <header-bar></header-bar>
      </a-layout-header>
      <a-layout class="ant-sub-layout">
        <a-layout-sider breakpoint="lg" collapsed-width="0" @collapse="onCollapse">
          <div class="sidebar-wrap">
            <side-bar></side-bar>
          </div>
        </a-layout-sider>
        <a-layout-content>
          <div class="base-content" :class="siderWidth === 0 ? 'closed' : ''" v-if="organizationId">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import SideBar from './c/SideBar'
import HeaderBar from './c/HeaderBar'
import { mapState } from 'vuex'
import api from '@/api'

export default {
  name: 'layout_base',
  components: {
    SideBar,
    HeaderBar
  },
  data() {
    return {
      organizationId: '',
      protalTitle: ''
    }
  },
  computed: {
    ...mapState(['siderWidth'])
  },
  mounted() {
    this.initOrganizationByDomain()
  },
  methods: {
    async initOrganizationByDomain() {
      const domain = window.location.origin
      let res = await api.protal.getOrganizationByDomain({ domain })
      if (res.code === 200 && res.data) {
        this.organizationId = res.data.organizationId
        this.protalTitle = res.data.organizationName || ''
        localStorage.setItem('organizationId', res.data.organizationId)
      } else {
        // TODO: 测试用
        if (
          process.env.NODE_ENV === 'development' ||
          (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_BASE_URL)
        ) {
          this.organizationId = 100000
          localStorage.setItem('organizationId', 100000)
        }
      }
      // TODO: 动态设置图标
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = `./${this.organizationId}.ico`
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    onCollapse(collapsed, type) {
      if (collapsed) {
        this.$store.commit('setSiderWidth', 0)
      } else {
        this.$store.commit('setSiderWidth', 200)
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar-wrap {
  min-height: calc(100vh - 64px);
  padding: 20px;
}
.ant-sub-layout {
  max-width: 1200px;
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
}
.base-content {
  width: 100%;
  padding: 20px;
  // &.closed {
  //   min-width: 100vw;
  // }
}
@media screen and (max-width: 1200px) {
  .base-content {
    min-width: calc(100vw - 280px);
  }
}
</style>
