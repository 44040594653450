<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 17:02:28
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-24 16:58:47
 * @FilePath: \crm-education\src\layouts\c\SideBar.vue
-->
<template>
  <div class="side-bar">
    <a-menu theme="light" mode="inline" v-model="activeKey" @click="onMenuClick">
      <template v-if="userRole === 'STUDENT'">
        <a-menu-item key="/student">
          <div class="d-flex align-center">
            <div class="cus-icon home"></div>
            <span>首页</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_study">
          <div class="d-flex align-center">
            <div class="cus-icon study"></div>
            <span>我的学习</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_exam">
          <div class="d-flex align-center">
            <div class="cus-icon cj"></div>
            <span>我的考试</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_grade">
          <div class="d-flex align-center">
            <div class="cus-icon cj"></div>
            <span>我的成绩</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_school_role">
          <div class="d-flex align-center">
            <div class="cus-icon xj"></div>
            <span>我的学籍</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_textbook_send" v-if="Number(isIssue) === 1">
          <div class="d-flex align-center">
            <div class="cus-icon jc"></div>
            <span>教材发放</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_graduat">
          <div class="d-flex align-center">
            <div class="cus-icon graduat"></div>
            <span>我的毕业</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_class">
          <div class="d-flex align-center">
            <div class="cus-icon bj"></div>
            <span>我的班级</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_admission_test">
          <div class="d-flex align-center">
            <div class="cus-icon cs"></div>
            <span>入学测试</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/my_notice">
          <div class="d-flex align-center">
            <div class="cus-icon tz"></div>
            <span>消息通知</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/student/user_center">
          <div class="d-flex align-center">
            <div class="cus-icon tz"></div>
            <span>个人信息</span>
          </div>
        </a-menu-item>
      </template>
      <template v-else>
        <a-menu-item key="/teacher/course">
          <div class="d-flex align-center">
            <div class="cus-icon course"></div>
            <span>课程</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/teacher/task">
          <div class="d-flex align-center">
            <div class="cus-icon task"></div>
            <span>作业</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/teacher/test">
          <div class="d-flex align-center">
            <div class="cus-icon test"></div>
            <span>考试</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/teacher/thesis">
          <div class="d-flex align-center">
            <div class="cus-icon thesis"></div>
            <span>毕业论文</span>
          </div>
        </a-menu-item>
        <a-menu-item key="/teacher/notice">
          <div class="d-flex align-center">
            <div class="cus-icon tz"></div>
            <span>消息通知</span>
          </div>
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>

<script>
import CustomIconMixin from '@/mixins/custom-icon'
import { mapState } from 'vuex'
export default {
  name: 'sidebar',
  mixins: [CustomIconMixin],
  components: {},
  data() {
    return {
      activeKey: ['/'],
      isIssue: localStorage.getItem('issue')
    }
  },
  computed: {
    ...mapState(['userRole'])
  },
  mounted() {
    this.init(this.$route.path)
  },
  watch: {
    $route(val) {
      this.init(val.path)
    }
  },
  methods: {
    init(path) {
      // const path = this.$router.history.current.path
      // if (path === '/student') {
      //   this.activeKey = ['/student']
      // } else
      if (path === '/teacher') {
        this.activeKey = ['/teacher/course']
      } else {
        this.activeKey = [path]
      }
    },
    onMenuClick({ item, key, keyPath }) {
      this.$router.push(key)
    }
  }
}
</script>
<style lang="scss" scoped>
.side-bar {
  padding-top: 20px;
  background-color: #fff;
  .ant-menu-item {
    &.ant-menu-item-selected {
      background-color: #385dfb;
      color: #fff;
      .cus-icon {
        &.home {
          background: url('../../assets/img/sider-home-light.png') no-repeat center center;
        }
        &.study {
          background: url('../../assets/img/sider-mystudy-light.png') no-repeat center center;
        }
        &.cj {
          background: url('../../assets/img/sider-cj-light.png') no-repeat center center;
        }
        &.xj {
          background: url('../../assets/img/sider-xj-light.png') no-repeat center center;
        }
        &.jc {
          background: url('../../assets/img/sider-jc-light.png') no-repeat center center;
        }
        &.graduat {
          background: url('../../assets/img/sider-graduat-light.png') no-repeat center center;
        }
        &.bj {
          background: url('../../assets/img/sider-bj-light.png') no-repeat center center;
        }
        &.cs {
          background: url('../../assets/img/sider-cs-light.png') no-repeat center center;
        }
        &.tz {
          background: url('../../assets/img/sider-tz-light.png') no-repeat center center;
        }
        &.course {
          background: url('../../assets/img/sider-course-light.png') no-repeat center center;
        }
        &.task {
          background: url('../../assets/img/sider-task-light.png') no-repeat center center;
        }
        &.test {
          background: url('../../assets/img/sider-test-light.png') no-repeat center center;
        }
        &.thesis {
          background: url('../../assets/img/sider-thesis-light.png') no-repeat center center;
        }
      }
    }
  }
  .cus-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    &.home {
      background: url('../../assets/img/sider-home.png') no-repeat center center;
    }
    &.study {
      background: url('../../assets/img/sider-mystudy.png') no-repeat center center;
    }
    &.cj {
      background: url('../../assets/img/sider-cj.png') no-repeat center center;
    }
    &.xj {
      background: url('../../assets/img/sider-xj.png') no-repeat center center;
    }
    &.jc {
      background: url('../../assets/img/sider-jc.png') no-repeat center center;
    }
    &.graduat {
      background: url('../../assets/img/sider-graduat.png') no-repeat center center;
    }
    &.bj {
      background: url('../../assets/img/sider-bj.png') no-repeat center center;
    }
    &.cs {
      background: url('../../assets/img/sider-cs.png') no-repeat center center;
    }
    &.tz {
      background: url('../../assets/img/sider-tz.png') no-repeat center center;
    }
    &.course {
      background: url('../../assets/img/sider-course.png') no-repeat center center;
    }
    &.task {
      background: url('../../assets/img/sider-task.png') no-repeat center center;
    }
    &.test {
      background: url('../../assets/img/sider-test.png') no-repeat center center;
    }
    &.thesis {
      background: url('../../assets/img/sider-thesis.png') no-repeat center center;
    }
  }
}
</style>
